import * as React from 'react';
import Layout from '../components/Layout';

const services = (): JSX.Element => {
  return (
    <Layout theme="">
      <div>
        <article>
          <h1>Postępowania sądowe cywilne</h1>
          <p>
            Kancelaria reprezentuje klientów na terenie całego kraju przed
            Sądami Rejonowymi, Okręgowymi, Apelacyjnymi oraz przed Sądem
            Najwyższym. Prawnicy kancelarii biorą udział w postępowaniach
            arbitrażowych i mediacyjnych. Kancelaria reprezentuje swoich
            klientów w sprawach o własność, odszkodowawczych, umów
            cywilno-prawnych, realizacji roszczeń za niewykonanie lub
            niewłaściwe wykonanie umów.
          </p>
        </article>
        <article>
          <h1>Nieruchomości</h1>
          <p>
            Kancelaria prowadzi obsługę z zakresu zagospodarowania i zarządzania
            nieruchomościami. Kancelaria prowadzi sprawy związane z uzyskaniem
            planów zagospodarowania przestrzennego, warunków zabudowy terenu
            oraz zmiany przeznaczenia gruntów rolnych na cele nierolnicze.
            Klienci Kancelarii w toku procesu inwestycyjnego objęci są pełną
            obsługą prawną, na którą składa się także uzyskanie pozwolenia na
            budowę oraz reprezentowanie we wszelkich postępowaniach
            administracyjnych związanych z w/w problematyką zarówno przez
            urzędami jak i sądami. Kancelaria specjalizuje także w obsłudze
            spraw związanych ze sprzedażą, oddawaniem w trwały zarząd,
            przekazywaniem nieruchomości na cele szczególne, ustalaniem sposobu
            i terminów zagospodarowania nieruchomości gruntowych, opłatami i
            rozliczeniami za nieruchomości, podziałem i scalaniem nieruchomości,
            realizacją prawa pierwokupu nieruchomości, wywłaszczaniem
            nieruchomości, odszkodowaniami za wywłaszczone nieruchomości,
            zwrotem wywłaszczonych nieruchomości, udziałem w kosztach budowy w
            tym urządzeń infrastruktury technicznej.
          </p>
        </article>
        <article>
          <h1>Prawo rodzinne</h1>
          <p>
            Kancelaria prowadzi sprawy rozwodowe, sprawy o alimenty oraz z
            zakresu władzy rodzicielskiej i kontaktów z dziećmi i dziadków z
            wnukami jak też sprawy o przysposobienie. Kancelaria posiada
            wieloletnie doświadczenie w zakresie opracowania i wdrożenia planów
            rodzicielskich oraz egzekucji kontaktów i alimentów uregulowanych i
            zasądzonych przez sąd. Kancelaria stosuje i wdraża szereg postępowań
            zabezpieczających związanych z roszczeniami swoich klientów
            realizowanymi w toku postępowań rozwodowych i innych z zakresu prawa
            rodzinnego i opiekuńczego.
          </p>
        </article>
        <article>
          <h1>Postępowania podziałowe majątkowe</h1>
          <p>
            Kancelaria prowadzi postępowania z zakresu podział majątku po
            ustaniu związku małżeńskiego jeżeli w skład majątku wchodzi
            nieruchomość, jak też związane ze zniesieniem współwłasności
            nieruchomości. W toku postępowania kancelaria uzyskuje wycenę
            nieruchomości w formie operatu szacunkowego, opracowuje sposób jej
            podziału oraz spłaty na rzecz poszczególnych współwłaścicieli.
          </p>
        </article>
        <article>
          <h1>Prawo spadkowe</h1>
          <p>
            Kancelaria prowadzi sprawy o nabycie spadku z ustawy albo z
            testamentu, o wydziedziczenie, o podważenie testamentu, o
            spadkobranie z testamentu ustnego albo szczególnego. Zakres usług
            obejmuje rozliczenie długów spadkowych, poleceń, zapisów i darowizn.
            Kancelaria prowadzi sprawy o zachowek oraz związane z
            wydziedziczeniem i zapisem windykacyjnym.
          </p>
        </article>

        <article>
          <h1>Prawo kontraktowe</h1>
          <p>
            Kancelaria tworzy, przekształca, uczestniczy w negocjacjach i
            renegocjacjach umów zawieranych przy prowadzeniu działalności
            gospodarczej w celu realizacji najbardziej skomplikowanych prawnie
            celów gospodarczych. Kancelaria jest właścicielem autorskich
            programów dotyczących analizy możliwie jak najszerszego spektrum
            ryzyk towarzyszących zawieraniu w/w umów. Programy te pozwalają
            optymalnie zabezpieczyć klientów kancelarii tak by regulacje umowne
            tworzyły przewagę konkurencyjną na rynku.
          </p>
        </article>

        <article>
          <h1>Prawo administracyjne</h1>
          <p>
            Kancelaria reprezentuje klientów przed; Wojewódzkimi Sądami
            Administracyjnymi, Naczelnym Sądem Administracyjnym oraz przed
            Trybunałem Konstytucyjnym w toku postępowania administracyjnego i
            sądowoadministracyjnego. Kancelaria prowadzi doradztwo w zakresie
            przygotowywania projektów aktów prawa miejscowego. Kancelaria
            uczestniczy w postępowaniach z zakresu nadzoru nad działalnością
            jednostek samorządu terytorialnego. Kancelaria doradza w zakresie
            stowarzyszeń i fundacji.
          </p>
        </article>

        <article>
          <h1>Podatki i cło</h1>

          <p>
            Kancelaria reprezentuje swoich klientów przez organami podatkowymi i
            celnymi. Uczestniczy w postępowaniach przez Wojewódzkimi Sądami
            Administracyjnymi i Naczelnym Sądem Administracyjnym w
            postępowaniach ze skargi na decyzję organów podatkowych lub celnych.
            Kancelaria prowadzi obsługę w toku postępowań egzekucyjnych z
            zakresu prawa podatkowego i celnego. Kancelaria prowadzi działalność
            w zakresie dozwolonej prawem optymalizacji podatkowej tj. projektuje
            umowy cywilnoprawne w ten sposób by dla klientów kancelarii tworzyły
            one jak najdogodniejsze rozwiązania a co za tym idzie korzystne
            zobowiązania podatkowe w przyszłości.
          </p>
        </article>
        <article>
          <h1>Prawo karne</h1>

          <p>
            Kancelaria prowadzi sprawy karne, w których jej klienci występują w
            charakterze oskarżonych, podejrzanych lub obwinionych. Klienci
            kancelarii są także reprezentowani w sprawach jako oskarżyciele
            posiłkowi oraz jako oskarżyciele prywatni. Obsługa karna kancelarii
            obejmuje także sprawy w postępowaniu wykonawczym. Obrona i
            reprezentacja stron w postępowaniu karnym obejmuje również pomoc
            prawną przed sądami wojskowymi. Kancelaria zapewnia klientom
            reprezentacje także w sprawach karno-skarbowych.
          </p>
        </article>
        <article>
          <h1>Prawo upadłościowe i naprawcze</h1>
          <p>
            Kancelaria doradza w sprawach w postępowaniach upadłościowych na
            terenie Polski, Unii Europejskiej i OECD. Kancelaria wdraża procesy
            naprawcze i układowe jeżeli sytuacja klienta nie wymaga wszczęcia
            postępowania upadłościowego. Przy wszystkich typach postępowań
            Kancelaria zapewnia pośrednictwo w obsłudze bankowej i finansowej. W
            zakresie postępowania upadłościowego pracownicy kancelarii kierują
            bieżącymi sprawami związanymi z masą upadłości zarówno przed jak i
            po wydaniu odpowiednich postanowień w postępowaniu upadłościowym i
            na bierząco współpracują z wyznaczonymi syndykami masy upadłości.
          </p>
        </article>

        <article>
          <h1>Prawo spółek</h1>

          <p>
            Kancelaria prowadzi, przygotowując pełną dokumentację, postępowanie
            w zakresie rejestracji spółek prawa handlowego na terenie Polski,
            Unii Europejskiej i OECD. Kancelaria prowadzi także sprawy z zakresu
            łączenia, podziału, przekształcenia spółek na wyżej wymienionym
            obszarze. Oferowana jest także pełna obsługa prawna bieżącej
            działalności spółek. Kancelaria prowadzi również usługę dedykowaną w
            postaci analizy rynku oraz analizę ekonomiczną inwestycji na terenie
            Polski, UE i OECD co pozwala klientom Kancelarii podejmować trafne
            decyzje inwestycyjne, zarówno na rynku lokalnym jak i ujęciu
            transgranicznym.
          </p>
        </article>

        <article>
          <h1>Działalność inwestycja</h1>

          <p>
            Kancelaria prowadzi programy inwestycyjne, na które składają się
            takie etapy jak; założenie podmiotu, postępowanie w zakresie
            pozyskania kapitału, postępowanie w zakresie uzyskaniu dotacji,
            prowadzenie pełnej obsługi prawnej podmiotu oraz współdziałanie
            kapitałowe i organizacyjne mające na celu ekspansję i rozwój. W celu
            realizacji tego typu projektów Kancelaria współpracuje z podmiotami
            poszukującymi perspektywicznych pomysłów na biznes. Realizacje wyżej
            wymienionych zadań Kancelaria wykonuje poprzez współdziałanie z
            funduszami private quity i venture capital. Szeroki wachlarz
            instrumentów analitycznych pozwala precyzyjnie zaplanować każdy etap
            inwestycji, a autorskie biznesplany cieszą się dużym uznaniem w
            postępowaniu z funduszami inwestycyjnymi. Doświadczenie w
            negocjacjach w zakresie warunków współpracy, sposobów zabezpieczeń
            oczekiwanych ze strony funduszy inwestycyjnych, kontroli realizacji
            celów gospodarczych pozwala zaproponować klientom Kancelarii szeroką
            gamę usług związanych z tego typu działalnością.
          </p>
        </article>

        <article>
          <h1>Trusty i usługi powiernicze</h1>
          <p>
            Kancelaria prowadzi usługi powiernictwa polegającego na przejmowaniu
            majątku lub jego części swoich klientów, zarządzania oraz wydawania
            go określonym osobom na określonych w umowie warunkach. Kancelaria
            zawiera umowy fiducia cum creditore contracta, fiducia cum amico
            contracta. Kancelaria tworzy także i zarządza trustami na terenie
            Polski, UE i OECD w zakresie uregulowanym prawem właściwym dla
            określonych podmiotów.
          </p>
        </article>

        <article>
          <h1>Ubezpieczenia i reasekuracja</h1>
          <p>
            Kancelaria doradza swoim klientom w zakresie ubezpieczeń od
            wszelkich ryzyk związanych z prowadzeniem działalności gospodarczej.
            Dzięki współpracy z brokerami ubezpieczeniowymi prawnicy Kancelarii
            mają pełną wiedzę na temat rynku ubezpieczeń, usług świadczonych w
            tym sektorze oraz kosztów związanych z tego typu usługami.
            Kancelaria zarządza pakietami ubezpieczeń dla podmiotów
            gospodarczych i realizuje długofalowe programy ubezpieczeniowe.
          </p>
        </article>

        <article>
          <h1>Bankowość i finanse</h1>
          <p>
            Kancelaria zapewnia pośrednictwo pomiędzy klientem a bankami i
            instytucjami finansowymi i doradczymi przy realizacji projektów
            inwestycyjnych związanych z obsługą depozytową oraz kredytową.
            Tworzy, przekształca i analizuje pod względem prawnych dokumentację
            niezbędna do realizacji w/w projektów.
          </p>
        </article>

        <article>
          <h1>Prawo własności intelektualnej i prawo autorskie</h1>

          <p>
            Kancelaria prowadzi praktykę w zakresie postępowań rejestracyjnych
            na wielu płaszczyznach związanych z ochroną patentów, znaków
            towarowych oraz ochrony topografii układów scalonych. Wszelkie
            procesy innowacyjne, wiążące się w dużymi nakładami finansowymi są,
            na zlecenie klienta, chronione prawnie zarówno podczas ich
            tworzenia, wdrażania jak też eksploatacji. Prawa autorskie i
            pokrewne podlegają ochronie na podstawie umów cywilnoprawnych.
            Kancelaria specjalizuje się w tworzeniu m.in. takich umów jak
            licencyjne, eksploatacyjne, know-how, zachowania tajemnic, ich
            monitorowaniu na etapie wykonania oraz egzekucji praw z nich
            wynikających.
          </p>
        </article>

        <article>
          <h1>Prawo konkurencji</h1>
          <p>
            Kancelaria zapewnia klientom usługi z zakresu przepisów
            regulacyjnych i praktyk konkurencyjnych. Przeprowadza monitoring
            transakcji pod kątem prawa konkurencji. Usługi takie wiążą się także
            z kreowaniem strategii działania na rynku w celu uniknięcia
            wypełnienia znamion praktyk naruszających konkurencję. Kancelaria
            zabezpiecza prawnie swoich klientów przed nieuczciwą konkurencją
            poprzez wielopłaszczyznowe analizy rynku w zakresie związanym z
            przejawami nieuczciwej konkurencji oraz tworzeniem umów chroniących
            poszczególne interesu klientów.
          </p>
        </article>

        <article>
          <h1>Telekomunikacja i informatyka</h1>
          <p>
            Kancelaria zajmuje się pomocą prawną świadczoną podmiotom zajmującym
            się outsourcingiem, handlem elektronicznym, tworzeniem portali
            internetowych. Kancelaria prowadzi doradztwo w zakresie szeroko
            pojętych elektronicznych oświadczeń woli w celu realizacji potrzeb
            gospodarczych swoich klientów. Kancelaria specjalizuje się w
            postępowaniach dotyczących uzyskania uprawnień dla działalności
            telekomunikacyjnej oraz używania urządzeń radiowych, świadczenia i
            udostępniania usług telekomunikacyjnych, łączenia sieci
            telekomunikacyjnych i współpracy operatorów oraz w zakresie
            infrastruktury telekomunikacyjnej i urządzeń końcowych.
          </p>
        </article>

        <article>
          <h1>Prawo reklamy i mediów</h1>
          <p>
            Kancelaria prowadzi działalność na płaszczyźnie mediów
            elektronicznych i tradycyjnych. Klienci kancelarii korzystający
            m.in. z usług podmiotów obsługujących media społecznościowe są
            chronieni umowami cywilnoprawnymi realizującymi pełen zakres
            wybranych przez siebie usług. Klienci będący reklamodawcami
            korzystają z pełnej obsługi prawnej w zakresie poszczególnych
            kampanii reklamowych jak też dużych projektów realizowanych w kraju
            i za granicą.
          </p>
        </article>
        <article>
          <h1>Zamówienia publiczne</h1>
          <p>
            Kancelaria prowadzi obsługę prawną z zakresu postępowań zamówień
            publicznych. Postępowania przetargowe, negocjacje, zapytania o cenę
            czy też zamówienia z wolnej ręki, w których biorą udział klienci
            Kancelarii zarówno po stronie zamawiających jak też oferentów są
            postępowaniami, które objęte są pełną obsługą prawną, na którą
            składają się m.in. negocjacje, udział w postępowaniu oraz obsługa
            wykonania zawartej w toku odpowiedniego postępowania umowy.
          </p>
        </article>

        <article>
          <h1>Partnerstwo publiczno-prywatne</h1>
          <p>
            Kancelaria doradza podmiotom gospodarczym przy tworzeniu projektów
            opartych o partnerstwo publiczno – prywatne. Realizacja
            przedsięwzięć w udziałem sektora publicznego wymaga spełnienia
            szeregu warunków umożliwiających prowadzenie takiej formy
            działalności. Kancelaria kompleksowo analizuje wszelkie aspekty
            prawne dotyczące poszczególnych projektów zarówno na etapie ich
            tworzenia jak też wdrażania.
          </p>
        </article>

        <article>
          <h1>Prawo ochrony środowiska</h1>
          <p>
            Kancelaria prowadzi doradztwo w zakresie prawa ochrony środowiska
            oraz prawa ochrony przyrody. Działania doradcze Kancelarii skupiają
            się na przed inwestycyjnej analizie obowiązków jednostek
            organizacyjnych i osób fizycznych w zakresie ochrony środowiska,
            ochrony środowiska w działalności inwestycyjnej, ograniczeń w
            korzystaniu ze środowiska, korzystania z maszyn i innych urządzeń
            technicznych w procesach inwestycyjnych oraz doradzaniu w sprawie
            opłat za gospodarcze korzystanie ze środowiska i za wprowadzanie w
            nim zmian. Działalność Kancelarii rozciąga się także na pomoc prawną
            w toku realizowanej inwestycji. Obsługa prawna w zakresie gospodarki
            odpadami, poboru wody i odprowadzania ścieków, emisji gazów lub
            pyłów, ochrony przed hałasem, jakości gleby oraz ziemi jest także
            przedmiotem działalności doradczej Kancelarii.
          </p>
        </article>
      </div>
    </Layout>
  );
};

export default services;
